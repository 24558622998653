<template>
  <div class="property">
    <div class="sider">
      <router-link v-for="item in list" :key="item.path" :to="item.path" class="item"
        :class="curPath === item.path ? 'active' : ''">
        <span>
          {{ item.label }}
        </span>
        <img v-if="curPath === item.path" src="@/assets/menu-item-bg.png" alt="" />
      </router-link>
    </div>

    <div style="flex: 1; margin-right: 3vw">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          label: "实物资产台账",
          path: "/property/list",
        },
        //{
        //  label: "计量资产台账",
        //  path: "/property/metering",
        //},
        {
          label: "闲置资产台账",
          path: "/property/free",
        },
        {
          label: "资产采购",
          path: "/property/buy",
        },
        {
          label: "资产领用",
          path: "/property/use",
        },
        {
          label: "资产转移",
          path: "/property/transfer",
        },
        {
          label: "资产盘点",
          path: "/property/check",
        },
        {
          label: "资产报废",
          path: "/property/scrap",
        },
        {
          label: "月增资产总价值统计",
          path: "/property/analysis",
        },
      ],

      curPath: this.$route.path,
    };
  },

  watch: {
    "$route.path"(newV) {
      this.curPath = newV;
    },
  },
};
</script>

<style lang="less" scoped>
.property {
  display: flex;
  padding: 0 2vw 0 4vw;

  .sider {
    width: 15vw;
    padding: 2vh 1vw 3vh;
    box-sizing: border-box;

    background: linear-gradient(to left,
        rgba(55, 164, 231, 0) 0%,
        rgba(87, 161, 227, 0.2) 100%);

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3vh;

    .item {
      color: rgba(255, 255, 255, 0.6);
      position: relative;

      img {
        position: absolute;
        left: 0;
        bottom: -50%;
        width: 6em;
        display: block;
      }
    }

    .active {
      color: #fff;
    }
  }
}
</style>